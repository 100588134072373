import { render, staticRenderFns } from "./substitution.vue?vue&type=template&id=05cf2bdb&scoped=true&"
import script from "./substitution.vue?vue&type=script&lang=js&"
export * from "./substitution.vue?vue&type=script&lang=js&"
import style0 from "./substitution.vue?vue&type=style&index=0&id=05cf2bdb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05cf2bdb",
  null
  
)

export default component.exports