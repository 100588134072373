<template>
  <div class="box">
    <div class="space"></div>
    <div class="bread">
      <div class="w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">核心产品</el-breadcrumb-item>
          <el-breadcrumb-item class="active">掌邻帮帮</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="banner">
      <div class="text w">
        <h1>关于帮帮</h1>
        <div class="divide"></div>
        <div class="desc">
          帮帮倡导一站式020+共享社区，需求服务商品化，社区生活淘宝化，一键查找资源或发布需求，邻里互帮互助建立[掌邻帮帮app]
          +[掌邻社区生活服务站] +[掌邻5G共享智慧屏] +[掌邻无人配送机]
          产品矩阵，打造闭环的需求解决生态有偿互帮互助，让居解决社区生活所需，邻里共享资源，家更简单，让生活更美好~
        </div>
      </div>
      <div class="pic">
        <img src="../../assets/imgs/Home/bb.png" alt="" />
      </div>
    </div>
    <div class="section">
      <div class="part w">
        <h1>项目背景</h1>
        <div class="blocks">
          <div class="block" v-for="item in programBgList" :key="item.id">
            {{ item.name }}
          </div>
        </div>
        <div class="requests">
          <div class="request">
            <p>
              <span>Anyone：</span>
              万能的朋友圈，谁有认识会做某某事的人？
            </p>
            <p>能否帮忙我 ( 或我的家人朋友 ) 办件事？</p>
          </div>
          <div class="divided"></div>
          <div class="request">
            <p><span>Anyone：</span>万能的朋友圈，附近哪有卖某某物品的？</p>
            <p>能否帮我买一份送到某某地方来 ( 去 ) ？</p>
          </div>
        </div>
      </div>
    </div>

    <div class="points">
      <div class="point w">
        <h1>业务痛点</h1>
        <div class="img">
          <img src="../../assets/imgs/bb/point.png" alt="" />
        </div>
        <div class="back">
          <div class="head">
            <img src="../../assets/imgs/Home/down.png" alt="" />
            <p>
              通过业务的调研以及用户的反馈我们总结出了
              <br />
              以下4点线下业务目前所存在的问题
            </p>
          </div>
          <div class="pains">
            <div class="pain" v-for="item in painList" :key="item.id">
              <p>{{ item.name }}</p>
              <p>{{ item.reason }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="angle">
      <div class="w">
        <img src="@/assets/imgs/sjx.png" alt="" />
      </div>
    </div>

    <div class="common">
      <h1>解决方案</h1>

      <div class="case_top w">
        <h1>
          掌邻帮帮社区产品 由公司自主研发运营项目规划
          <span class="blue"> 四个阶段 </span>
        </h1>

        <div class="steps">
          <div class="dot"></div>
          <div class="step">
            <span>建立产品矩阵，打造闭环的需求解决生态</span>
            <span>
              最终实现
              <p>O2O+共享社区</p>
              ，需求服务商品化，社区生活淘宝化
            </span>
          </div>
        </div>
      </div>
      <div class="case_bottom w">
        <h2>&lt;&lt; 四个阶段 &gt;&gt;</h2>

        <div class="case_bottom_main">
          <div class="case_bottom-left">
            <div class="case" v-for="item in caseFixList" :key="item.id">
              <span class="circle"></span>
              <span class="name">{{ item.name }}</span>
            </div>
          </div>

          <div class="case_bottom_right">
            <img src="../../assets/imgs/bb/case.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="common bg_white">
      <h1>实现技术</h1>

      <div class="icons">
        <div class="icon" v-for="item in techologyList" :key="item.id">
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div class="tech_desc">
        产品基于LBS地理位置服务，采用了分布式敏捷开发技术，不同功能模块之间相互独立又相互配合，集成极光推送，云通知等主流服务框架，
        <br />
        实现消息的即时性，大数据分析预测需求，提供预期服务。
      </div>
    </div>
    <div class="common ach">
      <h1>获得成就</h1>

      <div class="achivement w">
        <p class="desc">
          公司自营站点成都理工大学站，曾获互联网+双创大赛银奖、成都理工大学国家重点项目
        </p>
        <p class="desc">
          现已建立站点微信社群50+个，历史订单超过1万单+，帮助人次超过1万+
        </p>
        <p class="desc">
          疫情期间建立扶残助老运营机制，累计帮扶困难残弱老病超过200户，协助社区网格团队多次组织开展活动
        </p>
        <p class="desc">受到用户、社区邻里、社区机构认可褒奖</p>

        <div class="achs">
          <div class="ach_ittem" v-for="item in achivementList" :key="item.id">
            <div class="top"></div>
            <div class="bottom">
              <span>{{ item.name }}</span>
              <span>{{ item.desc }}</span>
              <span class="dot"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="operate">
      <h1>合作招商</h1>

      <div class="operates w">
        <div class="desc">
          <span>一期项目</span>
          现已开始
          <span>全国招商</span>
          中，0费用0成本扶持创业想法，低投入运营社区跑腿帮办项目，项目回报可得丰厚订单收入、广告收入、流量转化收入，业态引流等各方面收入。
        </div>
        <div class="divide"></div>
        <div class="desc">
          诚邀您的加入，共建帮帮宏图，真正做到任何时间 、任何地点、
          任何个体有途径可找资源、有需求必得响应、有问题就找帮帮。使社区服务得以深入人心、助有梦之人实现社会价值、让互帮互助成为生活信仰。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',
  components: {},
  data() {
    return {
      programBgList: [
        { id: 1, name: '了解信息的需求' },
        { id: 2, name: '需要帮助的需求' },
        { id: 3, name: '增加收入的需求' }
      ],
      painList: [
        { id: 1, name: '用户', reason: '资源不对等' },
        { id: 2, name: '商家', reason: '经营难运营难' },
        { id: 3, name: '平台', reason: '推广难运营难' },
        { id: 4, name: '社区', reason: '管理难保障难' }
      ],

      caseFixList: [
        { id: 1, name: '【掌邻帮帮app】' },
        { id: 2, name: '【掌邻社区生活服务站】' },
        { id: 3, name: '【掌邻5G共享智慧屏】' },
        { id: 4, name: '【掌邻无人配送机】' }
      ],

      techologyList: [
        {
          id: 1,
          name: 'LBS地理位置服务',
          img: require('../../assets/imgs/bb/lbs.png')
        },
        {
          id: 2,
          name: '分布式敏捷开发',
          img: require('../../assets/imgs/bb/fbs.png')
        },
        {
          id: 3,
          name: '集成激光推送',
          img: require('../../assets/imgs/bb/jg.png')
        },
        {
          id: 4,
          name: '云通知',
          img: require('../../assets/imgs/bb/ytz.png')
        },
        {
          id: 5,
          name: '大数据分析预测需求',
          img: require('../../assets/imgs/bb/dsj.png')
        }
      ],

      achivementList: [
        { id: 1, name: '国家重点项目', desc: '成都理工大学站点' },
        { id: 2, name: '50+', desc: '微信社群' },
        { id: 3, name: '1W+', desc: '历史订单' },
        { id: 4, name: '1W+', desc: '服务人次' },
        { id: 5, name: '超过200户', desc: '帮扶困难残弱老病' }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.box {
  .space {
    width: 100%;
    height: 80px;
    background-color: #2474ea;
  }
  .bread {
    display: flex;
    align-items: center;
    text-align: left;
    height: 60px;
    background-color: #2474ea;
    .active /deep/.el-breadcrumb__inner {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }

    /deep/.el-breadcrumb__inner {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .banner {
    height: 680px;
    background-color: #2474ea;
    text-align: left;
    position: relative;
    .text {
      width: 400px;
      position: absolute;
      top: 30%;
      left: 20%;
      h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 36px;
        color: #ffffff;
      }
      .divide {
        width: 18px;
        height: 2px;
        background: #ffffff;
        margin: 74px 0 20px;
      }
      .desc {
        color: #fff;
        line-height: 30px;
        font-size: 16px;
      }
    }
    .pic {
      width: 464px;
      position: absolute;
      right: 20%;
      top: 10%;
      z-index: 2;
    }
  }
  .section {
    height: 660px;
    text-align: left;
    background-color: #f5f9ff;

    .part {
      padding: 100px 0 120px;
      box-sizing: border-box;
      height: 100%;
      h1 {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #2a2f32;
      }
      .blocks {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 112px 0 56px;
        .block {
          width: 240px;
          height: 84px;
          text-align: center;
          line-height: 84px;
          border-radius: 100px;
          background: linear-gradient(0deg, #2474ea, #2474ea),
            linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
            linear-gradient(0deg, #2474ea, #2474ea);
          background-blend-mode: normal, normal, color, normal;
          border-radius: 100px;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
          transition: all 0.3s linear;
          &:hover {
            background: linear-gradient(0deg, #ffffff, #ffffff),
              linear-gradient(0deg, #c2dbff, #c2dbff);
            background-blend-mode: normal, color, normal;
            border-radius: 100px;
            color: #000;
            cursor: pointer;
          }
        }
      }
      .requests {
        width: 100%;
        height: 152px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .request {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 36px;
          color: #000000;

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 36px;
            color: #000000;
            span {
              font-weight: 700;
            }
          }
        }
        .divided {
          width: 0px;
          height: 52px;
          border: 1px solid #e6e6e6;
        }
      }
    }
  }
  .points {
    height: 854px;
    background: linear-gradient(180deg, #ffffff 0%, #e7ecfd 100%), #e7ecfd;
    text-align: left;
    .point {
      height: 100%;
      padding: 64px 0 90px;
      box-sizing: border-box;
      h1 {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #2a2f32;
      }
      .img {
        width: 1000px;
        height: 300px;
        margin: 48px auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .back {
        .head {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
          }
          p {
            margin-top: 14px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 36px;
            color: #2c4252;
          }
        }
        .pains {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 45px;
          .pain {
            width: 140px;
            height: 140px;
            background: #3f88ff;
            box-shadow: 0px 4px 10px rgba(18, 44, 113, 0.25);
            border-radius: 50%;
            text-align: center;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.3s linear;
            &:hover {
              background: #ffa622;
              cursor: pointer;
            }
            p {
              color: rgba(255, 255, 255, 0.9);
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 36px;
              &:first-child {
                font-size: 24px;
                color: #ffffff;
              }
            }
            &:nth-child(1),
            &:nth-child(4) {
              margin-top: -30px;
            }
            &:nth-child(2),
            &:nth-child(3) {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  .angle {
    width: 100%;
    height: 25px;
    background-color: #f5f9ff;
  }
  .common {
    width: 100%;
    height: 580px;
    background-color: #f5f9ff;
    padding: 104px 0 128px;
    box-sizing: border-box;

    &.ach {
      height: 720px;
      padding: 74px 0 52px;
      box-sizing: border-box;
    }
    &.bg_white {
      background-color: #fff;
    }

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #2a2f32;
    }

    .case_top {
      width: 1200px;
      margin-bottom: 40px;
      padding: 0 170px;
      box-sizing: border-box;

      h1 {
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #2c4252;
        margin-bottom: 16px;

        .blue {
          color: #87b5f3;
        }
      }
      .steps {
        display: flex;
        align-items: center;
        .dot {
          width: 2px;
          height: 50px;
          background: #bddfff;
          margin-right: 10px;
        }
        .step {
          text-align: left;
          display: flex;
          flex-direction: column;
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #516a7c;
            &:nth-child(1) {
              margin-bottom: 10px;
            }
            p {
              display: inline-block;
              color: #000;
              font-weight: 700;
            }
          }
        }
      }
    }
    .case_bottom {
      height: 248px;
      background-color: #fff;
      text-align: left;

      h2 {
        width: 1200px;
        height: 40px;
        line-height: 40px;
        padding: 0 170px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #3f88ff;
        background: linear-gradient(
          90deg,
          rgba(162, 209, 255, 0.3) 0%,
          rgba(162, 209, 255, 0) 100%
        );
      }
      img {
        width: 10px;
        height: 10px;
      }
      .case_bottom_main {
        padding: 20px 190px;
        box-sizing: border-box;
        position: relative;

        .case_bottom-left {
          .case {
            .circle {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: 1px solid rgba(42, 47, 50, 0.3);
            }

            .name {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 36px;
              color: #2c4252;
            }
          }
        }

        .case_bottom_right {
          position: absolute;
          width: 343px;
          height: 459px;
          right: 80px;
          top: -252px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .icons {
      width: 1200px;
      margin: 60px auto 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 76px;
          height: 76px;
          margin-bottom: 20px;
          object-fit: cover;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          color: #2c4252;
        }
      }
    }

    .tech_desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #516a7c;
    }

    .achivement {
      width: 1200px;
      height: 520px;
      margin-top: 32px;
      padding: 80px 68px 68px;
      box-sizing: border-box;

      background: linear-gradient(
        164.23deg,
        #e7f1ff 6.9%,
        #ffffff 16.54%,
        #ffffff 84.25%,
        #f0efff 93.36%
      );
      border: 1px solid #ffffff;

      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #516a7c;
        padding: 0 47px;
        box-sizing: border-box;
      }

      .achs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 60px;

        .ach_ittem {
          width: 200px;
          height: 200px;
          background-color: #fff;
          border: 1px solid #eeeeee;
          border-radius: 8px;
          .top {
            transition: all 0.3s linear;
            width: 200px;
            height: 72px;
            background: #eeeeee;
            border: 1px solid #eeeeee;
          }
          .bottom {
            transition: all 0.3s linear;
            height: 128px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
              font-style: normal;
              font-weight: 400;
              text-align: center;
              &:nth-child(1) {
                font-size: 20px;
                line-height: 36px;
                color: #2c4252;
                margin-bottom: 10px;
              }
              &:nth-child(2) {
                font-size: 14px;
                color: #516a7c;
              }
            }
            .dot {
              width: 14px;
              height: 4px;
              background: #ffffff;
              border-radius: 4px;
              margin-top: 20px;
              display: none;
            }
          }

          &:hover {
            cursor: pointer;
            background: linear-gradient(180deg, #437cf1 0%, #70acf7 100%);
            .top {
              height: 0;
              border: 0 solid transparent;
            }
            .bottom {
              height: 200px;
              border: 0 solid transparent;
              span {
                color: #ffffff;
              }
              .dot {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.operate {
  width: 100%;
  height: 520px;
  background: #1f3e69;

  padding: 64px 0 170px;
  box-sizing: border-box;
  h1 {
    margin-bottom: 104px;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #ffffff;
  }

  .operates {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.76);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    .desc {
      width: 46%;
      height: 200px;
      padding: 40px 0;
      box-sizing: border-box;
      span {
        font-size: 24px;
        color: #fff;
      }
    }

    .divide {
      width: 164px;
      height: 0px;
      opacity: 0.1;
      border: 1px solid #ffffff;
      transform: rotate(90deg);
      // margin: 0 45px;
    }
  }
}
</style>
